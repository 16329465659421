import { PublicEnv } from '@/public-env'

export const FB_PIXEL_ID = PublicEnv.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const pageview = () => {
  if (PublicEnv.NEXT_PUBLIC_STAGE === 'production') {
    window.fbq('track', 'PageView')
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options: Record<string, any> = {}) => {
  if (PublicEnv.NEXT_PUBLIC_STAGE === 'production') {
    window.fbq('track', name, options)
  }
}
