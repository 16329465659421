import { trpc } from '@/utils/trpc'
import { useUser } from '@clerk/nextjs'

export const useProfile = () => {
  const { isLoaded, user } = useUser()

  const profile = trpc.profile.profile.useQuery(undefined, {
    enabled: isLoaded && Boolean(user?.id),
  })

  return profile
}
