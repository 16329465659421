import { PropsWithChildren, useEffect } from 'react'
import { useUser } from '@clerk/nextjs'
import * as fbq from '@/lib/facebook-pixel'
import { useRouter } from 'next/router'
import { trpc } from '../utils/trpc'
import { useAuth, useOrganization } from '@clerk/nextjs'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import mixpanel from 'mixpanel-browser'
import { useProfile } from '@/hooks/user/use-profile'
import { PublicEnv } from '@/public-env'
import Script from 'next/script'
import { useIntercom } from 'react-use-intercom'

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { user, isLoaded: userLoaded } = useUser()
  const posthog = usePostHog()
  const router = useRouter()
  const { boot } = useIntercom()

  const profile = useProfile()

  const auth = useAuth()

  const intercom = trpc.profile.intercom.useQuery(undefined, {
    enabled: Boolean(user),
  })

  const { organization, isLoaded: organizationLoaded } = useOrganization()

  useEffect(() => {
    // Track page views
    fbq.pageview()

    const handleRouteChange = () => {
      posthog.capture('$pageview')
      fbq.pageview()
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (!userLoaded) {
      return
    }

    if (user) {
      window.fbq('init', fbq.FB_PIXEL_ID, {
        em: user.primaryEmailAddress?.emailAddress.toLowerCase(),
        fn: user.firstName?.toLowerCase(),
        ln: user.lastName?.toLowerCase(),
        ph: user.primaryPhoneNumber?.phoneNumber,
        external_id: user.id,
      })
    }
  }, [userLoaded])

  useEffect(() => {
    if (!user || !profile.data || !intercom.data || !auth.isLoaded || !organizationLoaded) return

    if (auth.actor) {
      return
    }

    const createdAtInSeconds = user.createdAt
      ? Math.floor(user.createdAt.getTime() / 1000)
      : undefined

    if (organization?.id) {
      posthog.group('company', organization.id, {
        name: organization.name,
        date_created: organization.createdAt.toLocaleDateString(),
      })
    }

    boot({
      userId: user.id,
      name: user.firstName || undefined, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      phone: user.primaryPhoneNumber?.phoneNumber,
      email: user.primaryEmailAddress?.emailAddress, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      createdAt: createdAtInSeconds as number, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      userHash: intercom.data,
      avatar: {
        type: 'avatar',
        imageUrl: user.imageUrl,
      },
    })

    posthog.identify(user.id, {
      email: user.primaryEmailAddress?.emailAddress,
      name: user.fullName,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      organizationId: organization?.id,
    })

    mixpanel.identify(user.id)

    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.primaryEmailAddress?.emailAddress,
      plan: user.publicMetadata.planType,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      organizationId: organization?.id,
      // Add anything else about the user here
    })

    sendGTMEvent({
      event: 'setUserData',
      user_id: user.id,
      email_address: user.primaryEmailAddress?.emailAddress,
      phone_number: user.primaryPhoneNumber?.phoneNumber,
      full_name: user.fullName,
      first_name: user.firstName,
      last_name: user.lastName,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      user_created_at: createdAtInSeconds,
      profile_url: user.imageUrl,
      intercom_hash: intercom.data,
    })
  }, [user, profile.data, intercom.data, organizationLoaded])

  return (
    <PostHogProvider client={posthog}>
      <Script id="default-c">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
    
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
          });
          `}
      </Script>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <Script
        id="reddit-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${PublicEnv.NEXT_PUBLIC_REDDIT_PIXEL_ID}');rdt('track', 'PageVisit');
            `,
        }}
      />
      <GoogleTagManager gtmId="GTM-5K5PC9WF" />
      <Script
        src="https://js.hs-scripts.com/46467431.js"
        id="hs-script-loader"
        strategy="afterInteractive"
      />
      {children}
    </PostHogProvider>
  )
}
