import { useRouter } from 'next/router'
import { useLayoutEffect } from 'react'
import { useOrganization, useUser } from '@clerk/nextjs'
import { useProfile } from '@/hooks/user/use-profile'

export const useRouteProtection = () => {
  const router = useRouter()
  const { user, isLoaded: userLoaded } = useUser()
  const profile = useProfile()
  const { organization, isLoaded: organizationLoaded } = useOrganization()

  useLayoutEffect(() => {
    if (!userLoaded || !organizationLoaded || profile.isLoading) {
      return
    }

    if (router.pathname.startsWith('/admin')) {
      if (!user) {
        router.push('/')
        return
      }

      if (user.publicMetadata.userType !== 'ADMIN') {
        router.push('/')
        return
      }
    }

    if (router.pathname.startsWith('/dashboard')) {
      if (!user) {
        router.push('/pricing')
      } else {
        if (profile.data?.isSubscribed && profile.data.onboarded && !profile.data.organizationId) {
          router.push('/dashboard/create-organization')
        }

        if (organization?.id) {
          return
        }

        if (!profile.data?.isSubscribed) {
          if (organization?.id) {
            return
          }

          router.push('/pricing')
          return
        }

        if (!profile.data.onboarded) {
          router.push('/onboarding')
          return
        }
      }
    }

    if (router.pathname === '/onboarding') {
      if (!user || !profile.data?.isSubscribed) {
        router.push('/pricing')
        return
      }

      if (user.publicMetadata.onboarded) {
        router.push('/dashboard')
        return
      }
    }
  }, [user, router.pathname, profile.data])
}
